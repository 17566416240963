const titulos = {
  ce: "Cidade Empreendedora",
  dt: "Sistema Sebrae de Desenvolvimento Territorial",
  gov: "Governança Empreendedora",
  at: "Agentes Territoriais",
  sala: "Selo de Referência em Atendimento SEBRAE",
  territorio: "Territórios Empreendedores",
};

export default titulos;
