export const logoDT = {
  logo_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/logo_azul.png",
  logo_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/logo_preta.png",
  logo_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/logo_branca.png",
  logo_encurtada_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/logo_encurtada_azul.png",
  logo_encurtada_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/logo_encurtada_preta.png",
  logo_encurtada_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/logo_encurtada_branca.png",
  login_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/login_azul.png",
  login_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/NA/login_preto.png",
  tema: "preta",
  temaConstrate: "branca",
};

export const logoCE = {
  logo_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/logo_azul.png",
  logo_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/logo_preta.png",
  logo_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/logo_branca.png",
  logo_encurtada_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/logo_encurtada_azul.png",
  logo_encurtada_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/logo_encurtada_preta.png",
  logo_encurtada_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/logo_encurtada_branca.png",
  login_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/login_azul.png",
  login_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/CE/login_preta.jpg",
  tema: "preta",
  temaConstrate: "branca",
};

export const logoGOV = {
  logo_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/logo_azul.png",
  logo_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/logo_preta.png",
  logo_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/logo_branca.png",
  logo_encurtada_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/logo_encurtada_azul.png",
  logo_encurtada_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/logo_encurtada_preta.png",
  logo_encurtada_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/logo_encurtada_branca.ico",
  login_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/login_azul.png",
  login_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/GOV/login_preta.jpg",
  tema: "branca",
  temaConstrate: "branca",
};

export const logoAT = {
  logo_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/logo_azul.png",
  logo_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/logo_preta.png",
  logo_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/logo_branca.png",
  logo_encurtada_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/logo_encurtada_azul.png",
  logo_encurtada_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/logo_encurtada_preta.png",
  logo_encurtada_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/logo_encurtada_branca.png",
  login_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/login_azul.png",
  login_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/AT/login_preta.jpg",
  tema: "branca",
  temaConstrate: "branca",
};

export const logoSelo = {
  logo_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/logo_azul.png",
  logo_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/logo_preta.png",
  logo_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/logo_branca.png",
  logo_encurtada_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/logo_encurtada_azul.ico",
  logo_encurtada_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/logo_encurtada_preta.png",
  logo_encurtada_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/logo_encurtada_branca.ico",
  login_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/login_azul.png",
  login_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/SELO/login_preta.jpg",
  tema: "azul",
  temaConstrate: "azul",
};

export const logoTerritorio = {
  logo_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/logo_azul.png",
  logo_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/logo_preta.png",
  logo_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/logo_branca.png",
  logo_encurtada_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/logo_encurtada_azul.png",
  logo_encurtada_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/logo_encurtada_preta.png",
  logo_encurtada_branca:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/logo_encurtada_branca.png",
  login_azul:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/login_azul.png",
  login_preta:
    "https://slide1-images.s3.us-east-1.amazonaws.com/public/logo/TERRITORIO/login_preta.jpg",
  tema: "preta",
  temaConstrate: "branca",
};
