export default {
  isAuthenticated: (state) => state.isAuthenticated,
  isAdmin: (state) => state.isAdmin,
  loading: (state) => state.loading,
  token: (state) => state.token,
  user: (state) => state.user,
  expired: (state) => state.expired,
  lastRoute: (state) => state.lastRoute,
  uf: (state) => state.uf,
  logo: (state) => state.logo,
  svg: (state) => state.svg,
  mapa: (state) => state.mapa,
  isSala: (state) => state.isSala,
  isDT: (state) => state.isDT,
  config: (state) => state.config,
  isTerritorio: (state) => state.isTerritorio,
  tipoMapa: (state) => state.tipoMapa,
  title: (state) => state.title,
  isCE: (state) => state.isCE,

  logos: (state) => {
    const logos = {
      logo: state.logos[`logo_${state.logos.tema}`],
      logoEncurtada: state.logos[`logo_encurtada_${state.logos.tema}`],
      login: state.logos[`login_${state.logos.tema}`],
      logoBranca: state.logos[`logo_branca`],
      logoConstrate: state.logos[`logo_${state.logos.temaConstrate}`],
    };
    return logos;
  },
};
